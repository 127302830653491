<template lang="pug">
div(v-if='Nosotros')
    //-PC
    v-container(fluid).d-none.d-md-flex.negro
    v-container(fluid).d-none.d-md-flex.negro
        v-row.mt-12.ml-5.negro  
            v-col(cols=8)
                v-carousel(v-model='model' show-arrows=true hide-delimiters
                prev-icon='fas fa-chevron-circle-left' next-icon='fas fa-chevron-circle-right').my-slider
                    v-carousel-item(v-for='{imagen, nombre, cargo}, k in Nosotros.en_el_mejor_equipo.equipo' :key='k')
                                v-row.mt-12.pt-12.pl-8
                                    v-col(cols=4).mr-0.mt-6.pt-6.pr-12
                                        v-img(:src='imagen' width='240px' height='240px' contain ).imagen_borde.ml-12
                                    v-col(cols=8).pl-0.ml-0.mt-6.pt-6
                                        v-container(fluid).mt-12.pl-0.ml-0
                                            v-row.mt-3.ml-5
                                                h3(v-html='nombre' style='line-height : 60px; text-align: justify').white--text
                                            v-row.mb-3.ml-5
                                                h4(v-html='cargo' style='line-height : 30px; text-align: justify').grey--text.font-weight-light
            v-col(cols=4).mt-12.pt-12
                v-row.justify-center.align-center
                    h3(v-html='Nosotros.en_el_mejor_equipo.línea_1').primario--text.font-weight-bold.mt-12.pt-12
                    h3(v-html='Nosotros.en_el_mejor_equipo.línea_2').primario--text.font-weight-light
    //-Móvil
    v-container(fluid).d-md-none.negro
    v-container(fluid).d-md-none.negro
        v-row.negro  
            v-col(cols=12)
                h5(v-html='Nosotros.en_el_mejor_equipo.línea_1' style='text-align: center').primario--text.font-weight-bold
                h5(v-html='Nosotros.en_el_mejor_equipo.línea_2' style='text-align: center').primario--text.font-weight-light
        v-row.negro  
            v-carousel(v-model='model' show-arrows=true hide-delimiters prev-icon='fas fa-chevron-circle-left' next-icon='fas fa-chevron-circle-right'
            max-width=400)
                v-carousel-item(v-for='{imagen, nombre, cargo}, k in Nosotros.en_el_mejor_equipo.equipo_movil' :key='k')
                    v-row(align='center' justify='center').mt-12.pt-12
                        v-img(:src='imagen' width='140px' height='140px' contain ).imagen_borde_movil
                    v-row(align='center' justify='center').mt-12 
                        p(v-html='nombre' style='line-height : 20px; text-align: center').white--text.justify-center.body-1.font-weight-bold.mt-4 
                    v-row(align='center' justify='center').mt-2    
                        p(v-html='cargo' style='line-height : 10px; text-align: center').grey--text.font-weight-light.body-2
</template>
<script>
export default {
    data: ()=>({
        model: 0,
    }),
}
</script>
<style lang="sass">
    .divider_inicial
        max-height: 10px!important
        height: 9px!important
    .imagen_borde
        border-radius: 100%!important
</style>
